// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("local-time").start()
require("packs/lightgallery.min")

window.Rails = Rails

import 'bootstrap'
import 'data-confirm-modal'

$(document).on("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()

  if ($("body").hasClass("navbar-invert")){
    $("nav.navbar").toggleClass("navbar-light").toggleClass("navbar-dark");
    $(".navbar-brand-dark").removeClass("d-none");
    $(".navbar-brand-light").addClass("d-none");
  }

  $(window).scroll();

  $("#project").lightGallery({
    selector: ".image-trigger",
    download: false,
    share: false,
    showThumbByDefault: false
  });
  
})

$(window).scroll(function() {
  if ($("body").hasClass("navbar-transparentable")) {
    if ($(window).scrollTop() > 100) {
      $("body").removeClass("navbar-transparent");
    } else {
      $("body").addClass("navbar-transparent");
    }
  }
  
  if ($("body").hasClass("navbar-invertable")) {
    if ($(window).scrollTop() > 100) {
      $("body").removeClass("navbar-invert");
      $("nav.navbar").addClass("navbar-light").removeClass("navbar-dark");
      $(".navbar-brand-dark").addClass("d-none");
      $(".navbar-brand-light").removeClass("d-none");
    } else {
      $("body").addClass("navbar-invert");
      $("nav.navbar").removeClass("navbar-light").addClass("navbar-dark");
      $(".navbar-brand-dark").removeClass("d-none");
      $(".navbar-brand-light").addClass("d-none");
    }
  }
});